import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import H3 from '../../elements/typography/h3'
import { breakpoint, getColor } from '../../shared/theme'
import { Wrapper } from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'

const ContactFormWrapper = styled(SectionTransition)`
	padding: 48px 0 60px;

	@media ${breakpoint('tablet')} {
		padding: 180px 0 280px;
	}
`

const gridStyle = css`
	grid-column: -1 / 1;

	@media ${breakpoint('laptop')} {
		grid-column: 3 / span 10;
	}
`

const FormContainer = styled(Wrapper)``

const FormSection = styled.div`
	grid-column: span 14;

	@media ${breakpoint('tablet')} {
		grid-column: span 10;
	}
`

const FormWrapper = styled.div`
	form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		& > div {
			width: 100%;
			margin-bottom: 25px;

			@media ${breakpoint('tablet')} {
				width: 48%;
			}

			input,
			select {
				box-shadow: none;
				border-bottom: 2px solid black;
				padding-bottom: 0.5em;
				transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
				line-height: 1.65;
				width: 100%;

				&.invalid {
					border-color: ${getColor('orange')};
				}
			}

			&.hs_message {
				width: 100%;
				margin-bottom: 15px;

				@media ${breakpoint('tablet')} {
					margin-top: 111px;
					margin-bottom: 40px;
				}

				@media ${breakpoint('desktop')} {
					margin-top: 155px;
				}

				textarea {
					border: 2px solid black;
					height: 252px;
					width: 100%;
					padding: 10px;

					@media ${breakpoint('tablet')} {
						height: 275px;
					}

					&.invalid {
						border-color: ${getColor('orange')};
					}
				}

				label {
					font-size: 14px;
					font-weight: 800;
					margin-bottom: 15px;
					letter-spacing: 0.5px;

					@media ${breakpoint('tablet')} {
						margin-bottom: 40px;
						font-size: 24px;
						font-weight: 400;
					}

					@media ${breakpoint('desktop')} {
						font-size: 28px;
					}
				}
			}

			&.hs_hs_persona {
				div {
					position: relative;

					&::after {
						content: '';
						display: inline-block;
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-top: 6px solid black;
						margin-left: 5px;
						position: absolute;
						right: 10px;
						top: 15px;
					}
				}

				label {
					margin-bottom: 10px;

					@media ${breakpoint('tablet')} {
						margin-bottom: 10px;
					}
				}

				legend {
					margin-bottom: 24px;

					@media ${breakpoint('tablet')} {
						margin-bottom: 42px;
					}
				}
			}

			&.hs_submit {
				input {
					background-color: rgb(0, 0, 0);
					border: 2px solid #000;
					color: #fff;
					font-weight: 800;
					text-transform: uppercase;
					text-align: left;
					position: relative;
					grid-column: -1 / 1;
					font-size: 8px;
					padding: 23px 64px 24px;
					width: auto;
					transition: all 0.2s ease-in-out;

					&:hover {
						color: #000;
						background-color: #fff;
					}

					@media ${breakpoint('tablet')} {
						padding: 30px 100px 29px;
						font-size: 12px;
					}
				}
			}

			label {
				margin-bottom: 24px;
				font-size: 12px;
				display: block;

				@media ${breakpoint('tablet')} {
					margin-bottom: 42px;
					font-size: 14px;
				}

				@media ${breakpoint('desktop')} {
					font-size: 18px;
				}
			}

			legend {
				font-size: 10px;
			}

			.hs-error-msg {
				margin-top: 1em;
				font-size: 10px;
				color: rgb(255, 77, 16);
				margin-bottom: 0;
				transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
			}
		}
	}
`

const Title = styled(H3)`
	grid-column: span 5;
	margin-bottom: 36px;
`

const Note = styled.div`
	grid-column: 14;
	font-size: 12px;

	span {
		font-style: italic;
		color: ${getColor('greyMid')};
		font-size: 8px;

		@media ${breakpoint('tablet')} {
			font-size: 18px;
		}
	}

	@media ${breakpoint('tablet')} {
		font-size: 18px;
		grid-column: span 5;
	}
`

const HiddenInput = styled.input``

const HeaderInner = styled(Wrapper)`
	${gridStyle};
`

const GridColumn = styled.div`
	grid-column: span 2;
	display: none;

	@media ${breakpoint('tablet')} {
		display: block;
	}
`

const GridColumnInner = styled.div`
	opacity: 0;
`

export const iAmAOptions = [
	{ label: 'Creator', value: 'creator' },
	{
		label: 'Multi-location fitness brand',
		value: 'Multi-location fitness brand',
	},
	{ label: 'Fashion/Lifestyle brand', value: 'Fashion/Lifestyle brand' },
	{ label: 'Other', value: 'Other' },
]

const ContactForm = ({ isActive }) => {
	useEffect(() => {
		const script = document.createElement('script')
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.onload = () => {
			if (window.hbspt) {
				const form = window.hbspt.forms.create({
					region: 'na1',
					portalId: '44219446',
					formId: 'a28f2bd1-f2f8-4c93-917c-53178c7370a7',
					target: '#contactForm',
				})
			}
		}

		document.body.appendChild(script)

		// Clean up the script tag on component unmount
		return () => {
			document.body.removeChild(script)
		}
	})

	return (
		<ContactFormWrapper isActive={isActive}>
			<InnerWrapper>
				<FormContainer>
					<GridColumn>
						<GridColumnInner>contact form</GridColumnInner>
					</GridColumn>
					<FormSection>
						<HeaderInner desktopColumns="10">
							<Title>Enquiries:</Title>
							<Note>
								*<span>Required Fields</span>
							</Note>
						</HeaderInner>
						<FormWrapper
							className="hubspot-form"
							id="contactForm"
						></FormWrapper>
					</FormSection>
				</FormContainer>
			</InnerWrapper>
		</ContactFormWrapper>
	)
}

ContactForm.propTypes = {
	isActive: PropTypes.bool,
}

ContactForm.defaultProps = {
	isActive: true,
}

export default ContactForm
