import React from 'react'

import SEO from '../../components/SEO'
import PageHeaderImage from '../../components/pageHeaderImage'
import PageIntroText from '../../components/pageHeaderIntro'
import ContactForm from '../../components/contactForm'
import InviewWrapper from '../../elements/inviewWrapper'

const ContactPage = ({ pageContext }) => {
	const {
		page: { title, default_page_header: pageHeader },
	} = pageContext

	return (
		<>
			<SEO title={title} />
			<PageHeaderImage content={pageHeader} />
			<InviewWrapper>
				<PageIntroText content={pageHeader} />
				<ContactForm />
			</InviewWrapper>
		</>
	)
}

export default ContactPage
